<template>
	<div class="box">
		<div class="success" v-if="flage">
			<img src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Paysuccess.png" />
			<p>支付成功!</p>
		</div>
		<div>
			<div class="success" v-if="!flage">
				<img src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/yc.png" />
				<P>支付订单存在异常,请联系:4006182019</P>
			</div>
		</div>
		<div class="btn">
			<button @click="$router.push('/')">返回首页</button>
			<button @click="selfun" v-if="!flage">已付款？点击查询订单！</button>
		</div>
	</div>
</template>

<script>
	const axi = require('../../assets/axi.js')
	export default{
		data(){
			return{
				flage:false
			}
		},
		mounted(){
			// alert(this.$store.state.prepayid)
			this.$store.state.prepayid
			this.selfun()
		},
		methods:{
			selfun(){
				axi.post('Pay/WeChatJSAPICallback?Prepay_id='+this.$store.state.prepayid).then((res)=>{
					console.log(res)
					if(res.data.Code==1000){
						this.flage=true
					}else{
						this.$message.error(res.data.Msg)
					}
					
				})
			}
		}
	}
</script>

<style scoped="scoped" lang="less">
	.success{
		width: 100vw;
		height: 60vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		align-content: center;
		p{
			width: 100%;
			text-align: center;
			margin-top: 30px;
			font-weight: bold;
		}
	}
	.btn{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		button{
			width: 60%;
			height: 40px;
			background-color: #5AB92B;
			color: #FFFFFF;
			border-radius: 40px;
			margin-bottom: 30px;
		}
	}
	
</style>
